import service from './request'

export function getAllOptions() {
  return service.get(`/options`)
}

export function getOptions(type, param = null) {
  return service.get(`/options/${type}`, param)
}

export function getImageList(param) {
  return service.get(`/images`, param)
}

// 获取所有配置
export function getAllSetting() {
  return service.get(`/settings/all`)
}

export function getSystemNotices(param) {
  return service.get(`/chatBusiness/notices`, param)
}

export function sendSystemNotice(param) {
  return service.post(`/chatBusiness/sendNotice`, param)
}

export function getSystemMessages(param) {
  return service.get(`/chatBusiness/messages`, param)
}

export function sendSystemMessage(param) {
  return service.post(`/chatBusiness/sendMessage`, param)
}

export function getApiLogList(param) {
  return service.get('/apiLogs', param)
}

export function getChatMemberList(param) {
  return service.get('/chatMembers', param)
}