export default {
  tokenKey: 'Chat-Admin-Token',
  // 管理后台信息
  website: {
    name: 'Chat后台管理系统',
    version: '1.0.0',
    desc: 'Chat IM 管理平台',
    // logo仅支持在线地址
    // logo: 'http://zhouyi.run:5222/api/public/admin/getFiles?id=5d38c26acf509bdcab4f8c677ce90088.png&&mimetype=image/png',
  },
  // 页脚备案信息
  reference: {
    show: true, //是否展示页脚
    number: '桂ICP备2022001745号-1',//备案号
    authorization: '元宇宙云计算', // 站点所属机构
  },
}